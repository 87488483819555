'use client';

import React, { FC, JSX, PropsWithChildren, ReactElement } from 'react';
import { DefaultPageProps } from '@/types/cms/magnolia';
import ContactBar from '@/components/contactBar/ContactBar';
import { THEMES } from '@/Util/globals';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';

const CountryPagesInner: FC<
  PropsWithChildren<
    Partial<DefaultPageProps> & {
      themeContactInformation: string;
      showContactBar: boolean;
      lowerContent?: ReactElement;
    }
  >
> = (props): JSX.Element => {
  const { children, header, lowerContent, showContactBar, themeContactInformation, footer } = props;

  return (
    <div className="country-page">
      <div className="page__top">
        {header && <EditableArea content={header} className="page__top__header" />}
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">{children}</div>
      {!!lowerContent ? lowerContent : <></>}
      <div className="page__bottom">{footer && <EditableArea content={footer} />}</div>
    </div>
  );
};

export default CountryPagesInner;
