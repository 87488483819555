import React, { FC, JSX } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { API_ITEM_SERVICE_CODE, getDefaultFormatPrice } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import classNames from 'classnames';
import { AllItems } from '@/components/checkout/cart/getAllItems';
import { TFunction } from 'i18next';
import { PurchaseRelatedService } from '@/components/checkout/cart/getAdditionalPurchases';

export const getPurchasesHeadline = (type: API_ITEM_SERVICE_CODE, t: TFunction): string => {
  switch (type) {
    case API_ITEM_SERVICE_CODE.MEAL:
      return t(Keys.mealShort);
    case API_ITEM_SERVICE_CODE.VISA:
      return t(Keys.visa);
    case API_ITEM_SERVICE_CODE.EXCURSION_PACKAGE:
      return t(Keys.excursionPackage);
    case API_ITEM_SERVICE_CODE.EXTENSION_PACKAGE:
    case API_ITEM_SERVICE_CODE.EXTENSION_PACKAGE_ALT1:
      return t(Keys.travelExtensions);
    case API_ITEM_SERVICE_CODE.EXTRA:
    case API_ITEM_SERVICE_CODE.FO_EXTRA:
    case API_ITEM_SERVICE_CODE.TRF_EXTRA:
      return t(Keys.extra);
    default:
      return t(Keys.excursion);
  }
};

const PurchaseItem: FC<{ item: PurchaseRelatedService; language: string }> = ({
  item: { name, pricePerPerson },
  language
}): JSX.Element => {
  const { t } = useTranslation('Checkout');

  return (
    <div className="checkout__data__price checkout__data__price--inner">
      <div>{name}</div>
      <div
        className={classNames({
          checkout__data__included: pricePerPerson === 0
        })}
      >
        {pricePerPerson === 0
          ? t(Keys.includedAlt)
          : getDefaultFormatPrice(pricePerPerson, language)}
      </div>
    </div>
  );
};

const AdditionalPurchases: FC<{ allItems: AllItems }> = observer(function AdditionalPurchases({
  allItems
}): JSX.Element {
  const language = useCurrentLanguage();
  const { t } = useTranslation('Checkout');

  const {
    additionalPurchases: { purchases, totalPricePerPerson }
  } = allItems;

  return purchases.length > 0 ? (
    <>
      <hr />
      <div className="cart__headline">{t(Keys.purchases)}</div>
      <div className="checkout__data">
        {purchases.map(({ bookedItem: item, type, relatedServices }) => {
          return (
            <div key={item.id} className="checkout__data__section">
              <FontAwesomeIcon icon={faPlus} />
              <div>
                <div className="font-weight-bold">{getPurchasesHeadline(type, t)}</div>
                <PurchaseItem
                  item={{
                    name: item.name,
                    pricePerPerson:
                      Object.values(item.priceByPersonId)[0].finalPrice -
                      (relatedServices && relatedServices.length > 0
                        ? relatedServices.reduce((total, current) => {
                            return total + current.pricePerPerson;
                          }, 0)
                        : 0),
                    totalPrice: 0
                  }}
                  language={language}
                />
                {relatedServices?.map(relatedService => (
                  <PurchaseItem
                    key={relatedService.name}
                    item={relatedService}
                    language={language}
                  />
                ))}
              </div>
            </div>
          );
        })}
        <div className="checkout__data__price">
          <FontAwesomeIcon icon={faPlus} />
          <div className="checkout__data__price__alt">
            <div>{t(Keys.pricePerPerson)}</div>
            <div
              className={classNames({
                checkout__data__included: totalPricePerPerson.finalPrice === 0
              })}
            >
              {totalPricePerPerson.finalPrice === 0
                ? t(Keys.includedAlt)
                : getDefaultFormatPrice(totalPricePerPerson.finalPrice, language)}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
});

export default AdditionalPurchases;
