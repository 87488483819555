'use client';

import React, { FC, useMemo } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { ContinentData } from '@/components/continentMap/helpers';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { getContinentLink } from '@/components/continentMap/desktop/InteractiveMap';
import { Link } from '@/Hooks/useReactTransitionProgress';

interface ContinentListProps {
  continents: ContinentData[];
}
const ContinentList: FC<ContinentListProps> = ({ continents }) => {
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });
  const globalProps = useGlobalMGLProps();

  const sortedContinents = useMemo(
    (): ContinentData[] =>
      continents.sort((a, b) => a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())),
    [continents]
  );

  return !isDesktop ? (
    <div className="continent-map__continent-list">
      {sortedContinents.map(continent => {
        return (
          <a
            key={continent.name}
            href={getContinentLink(continent.link, globalProps)}
            className="continent-map__continent-list__element"
          >
            <div className="continent-map__continent-list__element__label">
              <div className="continent-map__continent-list__element__title">{continent.name}</div>
              <div className="continent-map__continent-list__element__count">
                {continent.productCount}
              </div>
            </div>
            <div className="continent-map__continent-list__element__icon">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </a>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export default ContinentList;
