'use client';

import React, { FC, useMemo, JSX } from 'react';
import {
  ApiProductsCacheData,
  ApiProductsCacheResponse,
  ApiProductsCacheResponseVariants
} from '@ibe/api';
import { VARIANT_QUERY_PROP } from '@/Util/globals';
import { ApiProduct } from '../../../api/model';
import { getCheapestProduct } from '@/components/Search/helpersProduct';
import ResultsListItemCalendarContent from '@/components/SearchForResults/ResultsListItemCalendarContent';
import { Props } from '@/types/cms/magnolia';
import { getVariantParam } from '@/components/ProductsDatesPrices/getVariantParam';

const ResultsListItemCalendar: FC<{
  product: ApiProductsCacheResponse;
  productPageBasePath: string;
  pageProps?: Props;
}> = ({ product, productPageBasePath, pageProps }): JSX.Element => {
  const { teaserImage, productTitle, displayUrl, products, variants, travelTypes, review } =
    product;

  const [cheapestProduct, matchingVariant, cheapestAvailableProduct, matchingAvailableVariant] =
    useMemo((): [
      ApiProductsCacheData | undefined,
      ApiProductsCacheResponseVariants | undefined,
      ApiProductsCacheData | undefined,
      ApiProductsCacheResponseVariants | undefined
    ] => {
      const foundProduct = getCheapestProduct(product, undefined, false);
      const foundVariant: ApiProductsCacheResponseVariants | undefined = variants?.find(
        variant => !!foundProduct && foundProduct.packageCode === variant.packageCode
      );
      const foundAvailableProduct = getCheapestProduct(product, undefined, true);
      const foundAvailableVariant: ApiProductsCacheResponseVariants | undefined = variants?.find(
        variant =>
          !!foundAvailableProduct && foundAvailableProduct.packageCode === variant.packageCode
      );
      return [foundProduct, foundVariant, foundAvailableProduct, foundAvailableVariant];
    }, [product, products]);

  const hasAvailableProduct = useMemo((): boolean | undefined => {
    return products?.some(product => (product.availability || 0) > 0);
  }, [product, products]);

  const productDetailsLink = useMemo((): string => {
    if (!!productPageBasePath && !!displayUrl && !!cheapestAvailableProduct) {
      const productVariants = variants?.map(cacheVariant => {
        return {
          name: cacheVariant.packageCode
        } as ApiProduct;
      });
      const variantParam = getVariantParam(
        product,
        cheapestAvailableProduct?.travelStartDate,
        productVariants,
        cheapestAvailableProduct?.packageCode
      );
      return `/${productPageBasePath}/${displayUrl}${
        !!variantParam ? `?${VARIANT_QUERY_PROP}=${variantParam}` : ''
      }`;
    } else {
      return '#';
    }
  }, [productPageBasePath, displayUrl, cheapestAvailableProduct, variants, product]);

  return (
    <ResultsListItemCalendarContent
      productDetailsLink={productDetailsLink}
      hasAvailableProduct={hasAvailableProduct}
      cheapestProduct={cheapestAvailableProduct || cheapestProduct}
      teaserImage={
        cheapestAvailableProduct
          ? matchingAvailableVariant?.teaserImage || teaserImage
          : matchingVariant?.teaserImage || teaserImage
      }
      review={
        cheapestAvailableProduct
          ? matchingAvailableVariant?.review || review
          : matchingVariant?.review || review
      }
      travelTypes={
        cheapestAvailableProduct
          ? matchingAvailableVariant?.travelTypes || travelTypes
          : matchingVariant?.travelTypes || travelTypes
      }
      name={
        cheapestAvailableProduct
          ? matchingAvailableVariant?.productTitle || productTitle
          : matchingVariant?.productTitle || productTitle
      }
      pageProps={pageProps}
    />
  );
};

export default ResultsListItemCalendar;
