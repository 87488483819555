import { ApiProductsCacheData, ApiProductsCacheResponse } from '@ibe/api';
import dayjs from 'dayjs';

export const getCheapestProduct = (
  product: ApiProductsCacheResponse,
  matchingPackageCode?: string,
  onlyAvailable?: boolean
): ApiProductsCacheData | undefined => {
  let onlyAvailableProductsWhenFlagged = product.products?.filter(
    currentProduct => !onlyAvailable || (currentProduct?.availability || 0) > 0
  );

  if (!onlyAvailableProductsWhenFlagged || onlyAvailableProductsWhenFlagged.length === 0) {
    return undefined;
  }

  return onlyAvailableProductsWhenFlagged.reduce((total, current) => {
    return ((!!current.startingPriceAmount &&
      !!total.startingPriceAmount &&
      current.startingPriceAmount < total.startingPriceAmount) ||
      !total.startingPriceAmount) &&
      (!matchingPackageCode ||
        (!!matchingPackageCode && matchingPackageCode === current.packageCode))
      ? current
      : total;
  }, onlyAvailableProductsWhenFlagged[0]);
};

export const getProductByOccupancy = (
  productPackage: ApiProductsCacheResponse
): ApiProductsCacheData | undefined => {
  let productByOccupancy: ApiProductsCacheData | undefined;
  productByOccupancy = productPackage?.products?.find(
    product => product?.maxOccupancy === 2 && (product?.availability || 0) > 0
  );
  if (!productByOccupancy) {
    productByOccupancy = productPackage?.products?.find(
      product => product?.maxOccupancy === 1 && (product?.availability || 0) > 0
    );
  }
  return (
    productByOccupancy ||
    productPackage?.products?.reduce((total, current) => {
      return (current?.availability || 0) > 0 &&
        !!current?.maxOccupancy &&
        current.maxOccupancy <= (total?.maxOccupancy || 1) &&
        current.maxOccupancy > 2
        ? current
        : total;
    }, productPackage?.products?.[0])
  );
};

export const getEarliestProduct = (
  product: ApiProductsCacheResponse
): ApiProductsCacheData | undefined => {
  return product.products?.reduce((total, current) => {
    return (!!current.travelStartDate &&
      !!total.travelStartDate &&
      dayjs(current.travelStartDate).unix() < dayjs(total.travelStartDate).unix() &&
      (current.availability || 0) > 0) ||
      ((current.availability || 0) > 0 && (total.availability || 0) <= 0) ||
      !total.travelStartDate
      ? current
      : total;
  }, product.products?.[0]);
};

export const getShortestDurationProduct = (
  product: ApiProductsCacheResponse
): ApiProductsCacheData | undefined => {
  return product.products?.reduce((total, current) => {
    return (!!current.duration && !!total.duration && current.duration < total.duration) ||
      !total.duration
      ? current
      : total;
  }, product.products?.[0]);
};
