import React, { FC, JSX, useMemo, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import { default404Image } from '@ibe/components';
import { ApiExtraService } from '@ibe/api';
import Image from '@/components/image/Image';
import { getPrice, THEMES } from '@/Util/globals';
import {
  ApiAccommodation,
  ApiAccommodationFromJSON,
  ApiExtra,
  ApiImageFromJSON
} from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import Button from '@/components/Button';
import classNames from 'classnames';
import TruncateContent from '@/components/truncateContent/TruncateContent';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { Props } from '@/types/cms/magnolia';

const VisaTeaser: FC<{
  visa: ApiExtraService;
  checkoutStore: CheckoutStore;
  isSelected: boolean;
  componentId: string;
  pageProps?: Props;
}> = observer(function VisaTeaser({
  visa,
  checkoutStore,
  componentId,
  isSelected,
  pageProps
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const language = useCurrentLanguage();
  const [selectedVisaAsAccommodation, setSelectedVisaAsAccommodation] = useState<
    ApiAccommodation | undefined
  >(undefined);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const cmsExtra = useMemo(() => {
    return visa.metaInfo?.contentByMetaType?.extraContent?.content?.[visa.cmsCode] as
      | ApiExtra
      | undefined;
  }, [visa]);

  const onTruncate = (isTruncated: boolean): void => {
    setIsTruncated(isTruncated);
  };

  const handleClick = async (): Promise<void> => {
    checkoutStore.isLoading = true;
    if (isSelected) {
      await checkoutStore.deselectItemsInCart(componentId, [visa.id]);
    } else {
      await checkoutStore.selectItemsInCart(componentId, [visa.id]);
    }
  };

  return (
    <>
      <div className="checkout-teaser__container">
        <div className="checkout-teaser">
          <div className="checkout-teaser__left">
            {!!cmsExtra?.image ? (
              <Image
                image={cmsExtra.image}
                serverSideContext={pageProps?.albConfig.context}
                pageProps={pageProps}
              />
            ) : (
              <Image
                image={ApiImageFromJSON({
                  imageLink: default404Image,
                  title: 'default image',
                  caption: 'default image',
                  width: 100,
                  height: 100
                })}
                pageProps={pageProps}
                serverSideContext={pageProps?.albConfig.context}
              />
            )}
          </div>
          <div className="checkout-teaser__center">
            <h4>{visa.name}</h4>
            {!!cmsExtra?.description && (
              <div>
                <TruncateContent onTruncate={onTruncate}>{cmsExtra.description}</TruncateContent>
              </div>
            )}
            {!!cmsExtra?.description && isTruncated && (
              <div
                className="checkout-teaser__read-more"
                onClick={(): void =>
                  setSelectedVisaAsAccommodation(
                    ApiAccommodationFromJSON({
                      id: visa.id,
                      name: cmsExtra?.name || visa.name,
                      images: !!cmsExtra?.image ? [cmsExtra.image] : [],
                      shortDescription: cmsExtra.description,
                      rooms: []
                    })
                  )
                }
              >
                {t(Keys.readMore)}
              </div>
            )}
          </div>
          <div className="checkout-teaser__right">
            <div className="checkout-teaser__right__price">
              {!visa?.price?.finalPrice || visa?.price?.finalPrice === 0
                ? t(Keys.included)
                : getPrice(visa.price, language)}
            </div>
            <Button
              className={classNames('checkout-teaser__button', {
                'checkout-teaser__button--selected': isSelected
              })}
              color="primary"
              onClick={handleClick}
            >
              {isSelected ? t(Keys.remove) : t(Keys.add)}
            </Button>
          </div>
        </div>
      </div>
      <IsoModal
        className="accommodation__modal"
        toggle={(): void => setSelectedVisaAsAccommodation(undefined)}
        isOpen={!!selectedVisaAsAccommodation}
        onClose={(): void => setSelectedVisaAsAccommodation(undefined)}
        theme={THEMES.blue}
      >
        <div className="accommodation__modal__container">
          <AccommodationContent
            accommodation={selectedVisaAsAccommodation}
            simpleMode
            pageProps={pageProps}
          />
        </div>
      </IsoModal>
    </>
  );
});

export default VisaTeaser;
