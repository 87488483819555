import React, { FC, JSX, useMemo } from 'react';
import { ApiFlightLeg, ApiFlightSegment } from '@ibe/api';
import { ApiAirlineLogo } from '../../../api/model';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { FlightUtil } from '@ibe/components';
import Image from '@/components/image/Image';
import classNames from 'classnames';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { Props } from '@/types/cms/magnolia';

dayjs.extend(utc);

const getLogo = (
  leg: ApiFlightLeg,
  airlineLogos?: ApiAirlineLogo[]
): ApiAirlineLogo | undefined => {
  return airlineLogos?.find(
    logo => logo.code == leg.operatingCarrier.code || logo.code == leg.marketingCarrier.code
  );
};

const FlightLegs: FC<{
  segment: ApiFlightSegment;
  airlineLogos?: ApiAirlineLogo[];
  pageProps?: Props;
}> = ({ segment, airlineLogos, pageProps }): JSX.Element => {
  const { t } = useTranslation('Checkout');

  const { firstLeg, lastLeg, firstLegLogo, lastLegLogo, numberOfStops } = useMemo(() => {
    const firstLeg = segment.legs[0];
    const lastLeg = segment.legs[segment.legs.length - 1];
    return {
      firstLeg,
      lastLeg,
      firstLegLogo: getLogo(firstLeg, airlineLogos)?.logo,
      lastLegLogo: getLogo(lastLeg, airlineLogos)?.logo,
      numberOfStops: segment.legs.length - 1
    };
  }, [segment]);

  return (
    <div className="flight-legs">
      <div className="flight-legs__infos">
        <div className="flight-legs__time">
          {`${dayjs.utc(firstLeg.departure).format('HH:mm')}`}
        </div>
        <div className="flight-legs__location">{firstLeg.origin.code}</div>
      </div>
      {numberOfStops > 0 && !!firstLegLogo && (
        <Image
          image={firstLegLogo}
          alt={firstLegLogo.title || 'airline logo'}
          className="flight-legs__stops__image"
          useDefaultLoader
          serverSideContext={pageProps?.albConfig.context}
          pageProps={pageProps}
        />
      )}
      <div
        className={classNames('flight-legs__center', {
          'flight-legs__center--stops': numberOfStops > 0
        })}
      >
        {numberOfStops === 0 ? (
          <>
            <div className="flight-legs__duration">
              <FontAwesomeIcon icon={faPlane} />
              <span>{FlightUtil.getFlightDuration(segment.flightDuration).slice(0, -2)}</span>
            </div>
            {!!firstLegLogo && (
              <div className="flight-legs__image">
                <Image
                  image={firstLegLogo}
                  alt={firstLegLogo.title || 'airline logo'}
                  useDefaultLoader
                  serverSideContext={pageProps?.albConfig.context}
                  pageProps={pageProps}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flight-legs__stops" />
            <div className="flight-legs__count">{t(Keys.stop, { count: numberOfStops })}</div>
          </>
        )}
      </div>
      {numberOfStops > 0 && !!lastLegLogo && (
        <Image
          image={lastLegLogo}
          alt={lastLegLogo.title || 'airline logo'}
          className="flight-legs__stops__image"
          useDefaultLoader
          serverSideContext={pageProps?.albConfig.context}
          pageProps={pageProps}
        />
      )}
      <div className="flight-legs__infos flight-legs__infos--destination">
        <div className="flight-legs__time">
          {`${dayjs.utc(lastLeg.arrival).format('HH:mm')}`}
          {segment.dateDeviation > 0 && <sup>+{segment.dateDeviation}</sup>}
        </div>
        <div className="flight-legs__location">{lastLeg.destination.code}</div>
      </div>
    </div>
  );
};

export default FlightLegs;
