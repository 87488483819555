/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-09 15:35:28 */

const Keys = {
  europe: 'europe',
  africa: 'africa',
  middleAmerica: 'middleAmerica',
  northAmerica: 'northAmerica',
  antarctica: 'antarctica',
  asia: 'asia',
  australia: 'australia',
  caribbean: 'caribbean',
  middleEast: 'middleEast',
  southAmerica: 'southAmerica'
}; export default Keys;