import React, { Dispatch, FC, JSX, SetStateAction, useMemo, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import { default404Image } from '@ibe/components';
import { ApiExtraService } from '@ibe/api';
import Image from '@/components/image/Image';
import { getPrice } from '@/Util/globals';
import { ApiExcursion, ApiImageFromJSON } from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import Button from '@/components/Button';
import classNames from 'classnames';
import TruncateContent from '@/components/truncateContent/TruncateContent';
import { Props } from '@/types/cms/magnolia';

const Excursion: FC<{
  excursion: ApiExtraService;
  checkoutStore: CheckoutStore;
  setSelectedExcursion: Dispatch<SetStateAction<ApiExcursion | undefined>>;
  isSelected: boolean;
  componentId: string;
  selectOrDeselectExternalExcursion?: (excursion: ApiExtraService, select: boolean) => void;
  pageProps?: Props;
}> = observer(function Excursion({
  excursion,
  checkoutStore,
  setSelectedExcursion,
  isSelected,
  componentId,
  selectOrDeselectExternalExcursion,
  pageProps
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const language = useCurrentLanguage();
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const cmsExcursion = useMemo(() => {
    return excursion.metaInfo?.contentByMetaType?.excursionContent?.content?.[excursion.cmsCode] as
      | ApiExcursion
      | undefined;
  }, [excursion]);

  const onTruncate = (isTruncated: boolean): void => {
    setIsTruncated(isTruncated);
  };

  const handleClick = async (): Promise<void> => {
    checkoutStore.isLoading = true;
    if (isSelected) {
      if (!!selectOrDeselectExternalExcursion) {
        selectOrDeselectExternalExcursion(excursion, false);
      } else {
        await checkoutStore.deselectItemsInCart(componentId, [excursion.id]);
      }
    } else {
      if (!!selectOrDeselectExternalExcursion) {
        selectOrDeselectExternalExcursion(excursion, true);
      } else {
        await checkoutStore.selectItemsInCart(componentId, [excursion.id]);
      }
    }
  };

  const onRequest = useMemo(() => {
    return excursion.extraUnits.every(extraUnit => extraUnit.availability === 0);
  }, [excursion]);

  return (
    <div className="checkout-teaser__container">
      <div className="checkout-teaser">
        <div className="checkout-teaser__left">
          {!!cmsExcursion?.images && cmsExcursion.images.length > 0 ? (
            <Image
              image={cmsExcursion.images[0]}
              alt={cmsExcursion.images[0].title || 'excursion image'}
              serverSideContext={pageProps?.albConfig.context}
              pageProps={pageProps}
            />
          ) : (
            <Image
              image={ApiImageFromJSON({
                imageLink: default404Image,
                title: 'default image',
                alt: 'default image',
                width: 100,
                height: 100
              })}
              alt="default image"
              pageProps={pageProps}
              serverSideContext={pageProps?.albConfig.context}
            />
          )}
        </div>
        <div className="checkout-teaser__center">
          <h4>{excursion.name}</h4>
          {!!cmsExcursion?.description && (
            <div>
              <TruncateContent onTruncate={onTruncate}>{cmsExcursion?.description}</TruncateContent>
            </div>
          )}
          {!!cmsExcursion?.description && isTruncated && (
            <div
              className="checkout-teaser__read-more"
              onClick={(): void => setSelectedExcursion(cmsExcursion)}
            >
              {t(Keys.readMore)}
            </div>
          )}
        </div>
        <div className="checkout-teaser__right">
          <div>
            <div className="checkout-teaser__right__price">
              {!excursion?.price?.finalPrice || excursion?.price?.finalPrice === 0
                ? t(Keys.included)
                : getPrice(excursion.price, language)}
            </div>
            {!!onRequest && <div>{t(Keys.onRequest)}</div>}
          </div>
          <Button
            className={classNames('checkout-teaser__button', {
              'checkout-teaser__button--selected': isSelected
            })}
            color="primary"
            onClick={handleClick}
          >
            {isSelected ? t(Keys.remove) : t(Keys.add)}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default Excursion;
