/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-09 15:35:28 */

const Keys = {
  notSupported: 'notSupported',
  downloadLink: 'downloadLink',
  scrollForMore: 'scrollForMore',
  date: 'date',
  duration: 'duration',
  price: 'price',
  departure: 'departure',
  language: 'language',
  days: 'days'
}; export default Keys;