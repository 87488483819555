'use client';

import React, { FC, PropsWithChildren, JSX, ReactElement } from 'react';
import { DefaultPageProps } from '@/types/cms/magnolia';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import ContactBar from '@/components/contactBar/ContactBar';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { THEMES } from '@/Util/globals';
import ContentModuleDynamic from '@/components/contentModule/ContentModuleDynamic';
import { PageComponentProps } from '@/components/magnoliaPage/componentHelper';
import CityMain from '@/components/CityIntros/CityMain';
import Navigation from '@/components/navigation/Navigation';

export const CityPageInner: FC<
  PropsWithChildren<
    DefaultPageProps &
      PageComponentProps & {
        text: string;
        tel: string;
        mail: string;
        themeContactInformation: string;
        showContactBar: boolean;
        breadcrumb?: ReactElement;
        pathname: string;
      }
  >
> = (props): JSX.Element => {
  const {
    header,
    footer,
    themeContactInformation,
    showContactBar,
    children,
    upperPageContent,
    pageProps,
    pathname
  } = props;

  const { city } = useGlobalMGLProps() ?? {};
  const {
    name,
    mainPage,
    subPages,
    contentAreaTitle,
    contentArea,
    themeIntroMain,
    themeContentArea
  } = city || {};

  return (
    <div className="city-page">
      <div className="page__top">
        {header && <EditableArea className="page__top__header" content={header} />}
        {showContactBar && <ContactBar theme={themeContactInformation || THEMES.darkGreen} />}
      </div>
      <div className="page__center">
        {upperPageContent}
        {!!mainPage && !!subPages && (
          <Navigation
            className={themeIntroMain || THEMES.blue}
            items={[mainPage, ...subPages]}
            disableButton
          />
        )}
        <CityMain city={city} pageProps={pageProps} pathname={pathname} />
        {!!contentArea && contentArea.length > 0 && (
          <ContentModuleDynamic
            theme={themeContentArea}
            headline={contentAreaTitle ? contentAreaTitle : name}
            contentRow={contentArea}
            pageProps={pageProps}
          />
        )}
        {children}
      </div>
      <div className="page__bottom">{footer && <EditableArea content={footer} />}</div>
    </div>
  );
};

export default CityPageInner;
