import React, { FC, useMemo, useState, JSX } from 'react';
import { observer } from 'mobx-react';
import CheckoutStore, { ApiComponentAdvanced } from '@/templates/checkout/CheckoutStore';
import dayjs from 'dayjs';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { ApiComponent, ApiHotel } from '@ibe/api';
import HotelTeaser from '@/components/hotelSelection/HotelTeaser';
import { ApiAccommodation } from '../../../api/model';
import AccommodationContent from '@/components/accommodations/AccommodationContent';
import IsoModal from '@/components/IsoModal';
import { API_ITEM_SERVICE_CODE, THEMES } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

const getSortedSelectableHotels = (selectableItems: ApiHotel[]) => {
  return selectableItems
    .sort((a, b) => (a.price?.finalPrice || 0) - (b.price?.finalPrice || 0))
    .reduce((total: Record<string, ApiHotel[]>, current: ApiHotel) => {
      return {
        ...total,
        [current.code]: [...(total[current.code] || []), current].sort(
          (a, b) =>
            parseInt((a?.matchCode || '0').split('')[0], 10) -
            parseInt((b?.matchCode || '0').split('')[0], 10)
        )
      };
    }, {});
};

const HotelSelection: FC<{
  checkoutStore: CheckoutStore;
  externalHotels?: ApiComponentAdvanced<ApiHotel>[];
  selectExternalHotel?: (hotel: ApiHotel, roomUnitRateId?: string) => void;
  pageProps?: Props;
  isExtension?: boolean;
}> = observer(function HotelSelection({
  checkoutStore,
  externalHotels,
  selectExternalHotel,
  pageProps,
  isExtension
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const [selectedAccommodation, setSelectedAccommodation] = useState<ApiAccommodation | undefined>(
    undefined
  );

  const days = useMemo((): { start: number; end: number }[] => {
    return (
      externalHotels?.sort(
        (a: ApiComponentAdvanced<ApiHotel>, b: ApiComponentAdvanced<ApiHotel>) =>
          dayjs(a.startDate).unix() - dayjs(b.startDate).unix()
      ) || checkoutStore.hotels
    ).reduce(
      (
        total: { start: number; end: number }[],
        current: ApiComponent,
        idx: number,
        list: ApiComponent[]
      ) => {
        const start =
          (total[idx - 1] || { end: 1 }).end +
          dayjs(current.startDate).diff(
            idx > 0 ? dayjs(list[idx - 1]?.endDate) : dayjs(current.startDate),
            'day'
          );
        return [
          ...total,
          {
            start,
            end: start + dayjs(current.endDate).diff(dayjs(current.startDate), 'day')
          }
        ];
      },
      []
    );
  }, [checkoutStore.hotels, externalHotels]);

  return (
    <div className="hotel-selection">
      {(externalHotels || checkoutStore.hotels)
        .sort((a, b) => dayjs(a.startDate).unix() - dayjs(b.startDate).unix())
        .map((hotelComponent, idx: number) => (
          <div key={hotelComponent.id} className="hotel-selection__component">
            {(externalHotels || checkoutStore.hotels).length > 1 && (
              <p className="hotel-selection__date-group">{`${t(Keys.singleDay)} ${
                days[idx]?.start || 0
              } - ${days[idx]?.end || 0}`}</p>
            )}
            {Object.entries(
              getSortedSelectableHotels(JSON.parse(JSON.stringify(hotelComponent.selectableItems)))
            ).map(([, hotels]) => (
              <HotelTeaser
                key={hotels[0].id}
                hotel={hotels[0]}
                allHotels={hotels}
                componentId={hotelComponent.id}
                checkoutStore={checkoutStore}
                setSelectedAccommodation={setSelectedAccommodation}
                selectedHotel={hotelComponent.selectedItems[0]}
                totalNumberOfSelectables={
                  Object.keys(
                    getSortedSelectableHotels(
                      JSON.parse(JSON.stringify(hotelComponent.selectableItems))
                    )
                  ).length
                }
                serviceType={hotels[0].serviceType.code as API_ITEM_SERVICE_CODE}
                selectExternalHotel={selectExternalHotel}
                pageProps={pageProps}
                isExtension={isExtension}
              />
            ))}
          </div>
        ))}
      <IsoModal
        className="accommodation__modal"
        toggle={(): void => setSelectedAccommodation(undefined)}
        isOpen={!!selectedAccommodation}
        onClose={(): void => setSelectedAccommodation(undefined)}
        theme={THEMES.blue}
      >
        <div className="accommodation__modal__container">
          <AccommodationContent
            accommodation={selectedAccommodation}
            simpleMode
            pageProps={pageProps}
          />
        </div>
      </IsoModal>
    </div>
  );
});

export default HotelSelection;
