import { ApiBookedItem, ApiComponent, ApiExtraService, ApiPriceModifierType } from '@ibe/api';
import { getServiceTypeCode } from '@/components/checkout/cart/getUpgrades';
import { API_ITEM_SERVICE_CODE, logger } from '@/Util/globals';
import { PurchaseRelatedService } from '@/components/checkout/cart/getAdditionalPurchases';

export const getComponentPosition = (bookedItem: ApiBookedItem): number => {
  try {
    if (!!bookedItem.id) {
      const decodedId = JSON.parse(atob(bookedItem.id));
      if (!!decodedId) {
        const parsed = parseInt(decodedId.componentPosition, 10);
        return isNaN(parsed) ? -1 : parsed;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  } catch (e) {
    logger('error')('Unable to decode/parse', e);
  }
  return -1;
};

export const getExtensions = (
  extensions: ApiBookedItem[],
  packageComponents: ApiComponent[],
  extensionHotelsAndExcursions: ApiBookedItem[]
) => {
  return extensions.map(bookedItem => {
    let relatedServices: PurchaseRelatedService[] = [];
    const componentPosition = getComponentPosition(bookedItem);
    const extensionComponent = packageComponents[componentPosition - 1];
    if (extensionComponent) {
      const selectedItem = extensionComponent.selectedItems[0] as ApiExtraService;
      if (selectedItem) {
        selectedItem.relatedServices?.forEach(relatedService => {
          if (
            relatedService.selectedItems &&
            relatedService.selectedItems.length > 0 &&
            relatedService.selectedItems?.[0]?.price?.finalPrice &&
            extensionHotelsAndExcursions.some(item => {
              return getServiceTypeCode(item, true) === relatedService.code;
            })
          ) {
            relatedServices = [
              ...relatedServices,
              {
                name: relatedService.selectedItems?.[0]?.name || '',
                totalPrice: relatedService.selectedItems?.[0]?.price?.finalPrice || 0,
                pricePerPerson:
                  relatedService.selectedItems?.[0]?.price?.modifiers?.find(
                    modifier => modifier.type === ApiPriceModifierType.PARTICIPANT
                  )?.absolute || 0
              }
            ];
          }
        });
      }
    }

    return {
      type: API_ITEM_SERVICE_CODE.EXTENSION_PACKAGE,
      bookedItem: {
        ...bookedItem,
        price: {
          ...bookedItem.price,
          finalPrice:
            bookedItem.price.finalPrice +
            relatedServices.reduce((total, current) => {
              return total + current.totalPrice;
            }, 0)
        },
        priceByPersonId: Object.fromEntries(
          Object.entries(bookedItem.priceByPersonId).map(([key, priceItem]) => {
            return [
              key,
              {
                ...priceItem,
                finalPrice:
                  priceItem.finalPrice +
                  relatedServices.reduce((total, current) => {
                    return total + current.pricePerPerson;
                  }, 0)
              }
            ];
          })
        )
      },
      relatedServices
    };
  });
};
