/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-09 15:35:28 */

const Keys = {
  callUs: 'callUs',
  enquire: 'enquire',
  chatWithUs: 'chatWithUs',
  label: 'label',
  phone: 'phone',
  mail: 'mail'
}; export default Keys;