import React, { FC, JSX, useEffect } from 'react';
import { ApiProductsCacheResponse } from '@ibe/api';
import { Props } from '@/types/cms/magnolia';
import { getProductPageBasePath } from '@/Util/globals';
import { forceCheck } from 'react-lazyload';
import ResultsListTeaserView from '@/components/SearchForResults/ResultsListTeaserView';

const WebsiteSearchFull: FC<{
  results: ApiProductsCacheResponse[];
  pageProps?: Props;
  activeIndex: number;
}> = ({ results, pageProps, activeIndex }): JSX.Element => {
  const { rootNodePath, siteConfig } = pageProps || {};
  const tabIndex = 0;

  useEffect(() => {
    setTimeout((): void => {
      forceCheck();
    }, 300);
  }, [results]);

  return (
    <div
      style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
      className="website-search-full__results__list website-search-full__results__list--teasers"
    >
      <ResultsListTeaserView
        activeView={0}
        pageProps={pageProps}
        productPageBasePath={getProductPageBasePath(rootNodePath || '', siteConfig)}
        sortedPackages={results}
        offset={0}
        height={520}
      />
    </div>
  );
};

export default WebsiteSearchFull;
