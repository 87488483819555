import { useEffect, useState, RefObject } from 'react';

export const useDetectIntersection = (
  element: RefObject<HTMLElement | null>,
  rootMargin: string
) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const current = element?.current;
    if (!current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(
          entry.isIntersecting && entry.boundingClientRect.top > (entry?.rootBounds?.top || 0)
        );
      },
      { rootMargin }
    );
    current && observer?.observe(current);

    return () => current && observer.unobserve(current);
  }, []);

  return isVisible;
};
