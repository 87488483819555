import React, {
  Dispatch,
  FC,
  JSX,
  ReactElement,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import { observer } from 'mobx-react';
import { FormBuilder, FormRef } from '@ibe/components';
import { useApi } from '@/Hooks/useApi';
import getTravelerFormConfig, {
  getNotesFormConfig
} from '@/components/checkout/useTravelerFormConfig';
import DatePickerWrapper from '@/components/Datepickers/DatePickerWrapper';
import Button from '@/components/Button';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { ApiBaseData, ApiTravelerType } from '@ibe/api';
import { participant } from '@/Util/mocks';
import { TRAVELER_FORM_ADULT_LEGAL_MIN_AGE } from '@/Util/globals';
import {
  getRoyalAlbatrosTraveler,
  handleFormFieldsChange,
  handleRemarksFormChange,
  updateInsurances
} from '@/components/checkout/ParticipantsFormHelpers';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { OnlinePaymentCaller } from '@/templates/checkout/CheckoutPageInnerMarkup';
import { UseFormSetValue } from 'react-hook-form';
import { useAutofill } from '@/Hooks/useAutofill';
import { useDebounce } from '@/Hooks/useDebounce';
import { useDetectIntersection } from '@/Hooks/useDetectIntersection';

export type FormDataType = Record<string, string>;
export type FormRefs = Array<FormRef<FormDataType>>;

let timer: ReturnType<typeof setTimeout> | null = null;

const ParticipantsForm: FC<{
  checkoutStore: CheckoutStore;
  setIsOnlinePaymentDisabled: (active: boolean, caller: OnlinePaymentCaller) => void;
  participantsFormRefs: RefObject<FormRefs>;
  useParticipantsMock: boolean;
  setShowInsurances: Dispatch<SetStateAction<boolean>>;
  setInsurancesLoading: Dispatch<SetStateAction<boolean>>;
  insurancesLoading: boolean;
  insuranceContainerRef: RefObject<HTMLElement | null>;
  showInsurances: boolean;
}> = observer(function ParticipantsForm({
  checkoutStore,
  participantsFormRefs,
  setIsOnlinePaymentDisabled,
  useParticipantsMock,
  setShowInsurances,
  setInsurancesLoading,
  insurancesLoading,
  insuranceContainerRef,
  showInsurances
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const { isAuthor } = useGlobalMGLProps() || {};
  const api = useApi(isAuthor);
  const locale = useCurrentLanguage();
  const countryRef = useRef<Record<number, string>>({});
  const zipCodeRef = useRef<Record<number, string>>({});
  const emailRef = useRef<Record<number, string>>({});
  const confirmEmailRef = useRef<Record<number, string>>({});
  const royalAlbNoRef = useRef<Record<number, string>>({});
  const phoneRef = useRef<Record<number, string>>({});
  const countryCodeRef = useRef<Record<number, string>>({});
  const firstNameRef = useRef<Record<number, string>>({});
  const lastNameRef = useRef<Record<number, string>>({});
  const streetRef = useRef<Record<number, string>>({});
  const cityRef = useRef<Record<number, string>>({});
  const titleRef = useRef<Record<number, string>>({});
  const birthdateRef = useRef<Record<number, string>>({});
  const remarksRef = useRef<string | undefined>('');
  const ignoreCountryChangeForCountryCode = useRef<boolean>(false);
  const [postalCodes, setPostalCodes] = useState<Array<{ code: string; label: ReactElement }>>([]);

  useAutofill();
  const isVisible = useDetectIntersection(insuranceContainerRef, '0px');

  useEffect(() => {
    (async () => {
      if (isVisible && !showInsurances) {
        await updateInsurances(
          checkoutStore,
          t,
          setShowInsurances,
          setInsurancesLoading,
          insurancesLoading,
          participantsFormRefs,
          locale
        );
      }
    })();
  }, [isVisible, showInsurances]);

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const updateInsurancesDebounced = useDebounce(updateInsurances, 1000);

  const handleCopyFormData = (participantIndex: number): void => {
    if (!!participantsFormRefs?.current[0] && !!participantsFormRefs?.current[participantIndex]) {
      ignoreCountryChangeForCountryCode.current = true;
      const values = participantsFormRefs.current[0].getCurrentValues();
      const participantValues = participantsFormRefs.current[participantIndex].getCurrentValues();
      participantsFormRefs.current[participantIndex].resetForm({
        street: values.street,
        zipCode: values.zipCode,
        city: values.city,
        country: values.country,
        phone: values.phone,
        countryCode: values.countryCode,
        birthDate: participantValues.birthDate,
        email: participantValues.email,
        confirmEmail: participantValues.confirmEmail,
        firstName: participantValues.firstName,
        lastName: participantValues.lastName,
        title: participantValues.title
      });
    }
  };

  const exposeSeparateFieldValue = async (
    fieldName: string,
    value: Record<string, unknown>,
    participantIndex: number
  ): Promise<void> => {
    if (fieldName === 'zipCode') {
      const data = value as unknown as ApiBaseData;
      if (!!participantsFormRefs.current[participantIndex]) {
        participantsFormRefs.current[participantIndex].setValue('city', data.description || '');
        participantsFormRefs.current[participantIndex].setValue(
          'country',
          data?.group?.split(',')[data.group.split(',').length - 1]?.trim() || ''
        );
        await participantsFormRefs.current[participantIndex].triggerFieldValidation('city');
        await participantsFormRefs.current[participantIndex].triggerFieldValidation('country');
      }
      timer = setTimeout(() => {
        setPostalCodes([]);
      }, 1000);
    }
  };

  const onRoyalAlbBlur = (participantIndex: number) => async (): Promise<void> => {
    checkoutStore.isLoading = true;
    await getRoyalAlbatrosTraveler(
      emailRef,
      confirmEmailRef,
      royalAlbNoRef,
      countryRef,
      zipCodeRef,
      participantsFormRefs,
      participantIndex,
      api,
      checkoutStore,
      countryCodeRef
    );
    checkoutStore.isLoading = false;
  };

  return (
    <div className="participants-forms">
      {useParticipantsMock && (
        <div>
          <Button
            color="primary"
            onClick={(): void => {
              participantsFormRefs.current?.forEach((formRef, idx) => {
                if (idx < (checkoutStore.selectedPacificProduct?.maxOccupancy || 1)) {
                  formRef.resetForm(participant);
                  emailRef.current[idx] = participant.email;
                  confirmEmailRef.current[idx] = participant.confirmEmail;
                  zipCodeRef.current[idx] = participant.zipCode;
                  countryRef.current[idx] = participant.country;
                }
              });
            }}
          >
            Mock Data
          </Button>
        </div>
      )}
      {checkoutStore.booking?.travelers?.map((traveler, participantIndex) => (
        <div key={traveler.id} className="participants-forms__form">
          <h5>{`${t(Keys.travelParticipant, { count: participantIndex + 1 })} ${
            participantIndex === 0 ? t(Keys.travelApplicant) : ''
          } ${
            traveler.type !== ApiTravelerType.ADULT
              ? `(${t(Keys.childLabel, { age: traveler.age })})`
              : ''
          }`}</h5>
          {participantIndex !== 0 && (
            <Button
              color="primary"
              className="participants-forms__copy"
              onClick={(): void => handleCopyFormData(participantIndex)}
            >
              {t(Keys.copyData)}
            </Button>
          )}
          <FormBuilder
            formConfig={getTravelerFormConfig(t, {
              isFirstTraveler: participantIndex === 0,
              isChild: traveler.type !== ApiTravelerType.ADULT,
              adultMinAge:
                participantIndex === 0
                  ? TRAVELER_FORM_ADULT_LEGAL_MIN_AGE
                  : traveler.type !== ApiTravelerType.ADULT
                    ? traveler.age
                    : 0,
              onRoyalAlbBlur: onRoyalAlbBlur(participantIndex),
              participantIndex
            })}
            ref={(ref: FormRef<FormDataType> | null): void => {
              if (!!ref) {
                participantsFormRefs.current[participantIndex] = ref;
              }
            }}
            hideSubmitButton
            scrollToError
            mode="onChange"
            reValidateMode="onChange"
            externalData={{
              salutations: [...(checkoutStore.baseData?.salutations || [])],
              countries: [...(checkoutStore.baseData?.countries || [])],
              postalCodes: [...postalCodes],
              yupContext: {
                emailProp: 'email',
                startDate: checkoutStore.booking?.travelStartDate,
                adultMinAge:
                  participantIndex === 0
                    ? TRAVELER_FORM_ADULT_LEGAL_MIN_AGE
                    : traveler.type !== ApiTravelerType.ADULT
                      ? (traveler.age || 0) + 1
                      : 0
              }
            }}
            CustomFormComponents={{ datePicker: DatePickerWrapper }}
            onFormFieldsChange={async (formFields: Record<string, string>): Promise<void> => {
              await handleFormFieldsChange(
                formFields,
                participantIndex,
                api,
                locale,
                setPostalCodes,
                countryRef,
                zipCodeRef,
                emailRef,
                confirmEmailRef,
                royalAlbNoRef,
                phoneRef,
                countryCodeRef,
                ignoreCountryChangeForCountryCode,
                participantsFormRefs,
                firstNameRef,
                lastNameRef,
                streetRef,
                cityRef,
                titleRef,
                birthdateRef,
                checkoutStore,
                t,
                setShowInsurances,
                setInsurancesLoading,
                insurancesLoading,
                updateInsurancesDebounced
              );
            }}
            exposeSeparateFieldValue={(fieldName: string, value: Record<string, unknown>) =>
              exposeSeparateFieldValue(fieldName, value, participantIndex)
            }
          />
        </div>
      ))}
      <FormBuilder
        formConfig={getNotesFormConfig(t)}
        ref={(ref: FormRef<FormDataType> | null): void => {
          if (!!ref) {
            participantsFormRefs.current[checkoutStore.selectedPacificProduct?.maxOccupancy || 1] =
              ref;
          }
        }}
        onFormFieldsChange={(formFields, setValue) =>
          handleRemarksFormChange(
            formFields,
            setValue as UseFormSetValue<Record<string, unknown>>,
            setIsOnlinePaymentDisabled,
            remarksRef
          )
        }
        hideSubmitButton
        scrollToError
        mode="onChange"
        reValidateMode="onChange"
      />
    </div>
  );
});

export default ParticipantsForm;
