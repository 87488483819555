import { createContext, PropsWithChildren, useContext, useState, JSX } from 'react';
import { ApiService } from '@ibe/services';
import { useApi } from '@/Hooks/useApi';

type GlobalApiContextType = {
  api: ApiService;
};

export const GlobalApiContext = createContext<GlobalApiContextType>({
  api: {} as ApiService
});

export const GlobalApiProvider = ({
  children,
  isAuthor
}: PropsWithChildren<{ isAuthor?: boolean }>): JSX.Element => {
  const api = useApi(isAuthor);

  return <GlobalApiContext.Provider value={{ api }}>{children}</GlobalApiContext.Provider>;
};

export const useGlobalApi = (): GlobalApiContextType => {
  return useContext<GlobalApiContextType>(GlobalApiContext);
};
