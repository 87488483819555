import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { ApiExtraUnit, ApiHotel, ApiHotelRoom, ApiPriceModifierType } from '@ibe/api';
import { API_ITEM_SERVICE_CODE, getDefaultFormatPrice } from '@/Util/globals';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import classNames from 'classnames';
import SelectionItem from '@/components/selectionItems/SelectionItem';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { ApiRoom } from '../../../api/model';
import { Props } from '@/types/cms/magnolia';

const HotelTeaserRightSide: FC<{
  hotel: ApiHotel;
  selectedHotel?: ApiHotel;
  allRooms: ApiHotelRoom[];
  allExtraUnits: ApiExtraUnit[];
  cmsRooms: (ApiRoom | undefined)[];
  totalNumberOfSelectables: number;
  handleSelect: () => void;
  isActive: boolean;
  serviceType: API_ITEM_SERVICE_CODE;
  componentId: string;
  checkoutStore: CheckoutStore;
  pageProps?: Props;
  selectExternalHotel?: (hotel: ApiHotel, roomUnitRateId?: string) => void;
  isExtension?: boolean;
}> = observer(function HotelTeaserRightSide({
  hotel,
  selectedHotel,
  allRooms,
  allExtraUnits,
  cmsRooms,
  totalNumberOfSelectables,
  handleSelect,
  isActive,
  componentId,
  checkoutStore,
  pageProps,
  selectExternalHotel,
  isExtension
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const language = useCurrentLanguage();
  // normal hotel
  const selectedHotelPrice = selectedHotel?.rooms?.[0]?.roomrates?.[0]?.totalPrice?.modifiers?.find(
    modifier => modifier.type === ApiPriceModifierType.PARTICIPANT
  )?.absolute;
  // extension hotel
  const hotelPrice = hotel?.price?.modifiers?.find(
    ({ type }) => type === ApiPriceModifierType.PARTICIPANT
  )?.absolute;

  return allRooms.filter(Boolean).length === 1 ? (
    <div className="checkout-teaser__right checkout-teaser__right--single-room">
      <SelectionItem
        itemId={allRooms[0]?.id}
        selectedItemId={hotel?.rooms?.[0]?.id}
        cartSelectionId={allRooms[0]?.roomrates?.[0]?.id}
        isOnlyItem={allRooms.length === 1}
        totalNumberOfSelectableHotels={totalNumberOfSelectables}
        handleHotelSelect={!isActive ? handleSelect : undefined}
        isActiveHotel={isActive}
        componentId={componentId}
        checkoutStore={checkoutStore}
        name={allRooms[0]?.description}
        price={allRooms[0]?.roomrates?.[0]?.totalPrice}
        description={cmsRooms[0]?.description}
        image={cmsRooms[0]?.image}
        showImages={cmsRooms.every(room => !!room?.image)}
        pageProps={pageProps}
        selectExternalItem={
          !!selectExternalHotel
            ? () => {
                if (!!hotel) {
                  selectExternalHotel(hotel, allExtraUnits?.[0]?.unitRates?.[0]?.id);
                }
              }
            : undefined
        }
        onRequest={allRooms[0]?.roomrates[0].availability === 0}
      />
    </div>
  ) : (
    <div className="checkout-teaser__right">
      <div className="checkout-teaser__right__price">
        {selectedHotelPrice && !isExtension
          ? getDefaultFormatPrice(selectedHotelPrice || 0, language)
          : hotelPrice && isExtension
            ? getDefaultFormatPrice(hotelPrice || 0, language)
            : t(Keys.included)}
      </div>
      {totalNumberOfSelectables > 1 && (
        <button
          type="button"
          className={classNames('selection-item__select__button', {
            'selection-item__select__button--active': isActive
          })}
          onClick={!isActive ? handleSelect : undefined}
        >
          <span>{t(Keys.select)}</span>
          <div className="selection-item__select__button__radio" />
        </button>
      )}
    </div>
  );
});

export default HotelTeaserRightSide;
