/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-09 15:35:28 */

const Keys = {
  header: 'header',
  subHeader: 'subHeader',
  type: 'type',
  language: 'language',
  visum: 'visum',
  vaccination: 'vaccination',
  currency: 'currency',
  yes: 'yes',
  no: 'no'
}; export default Keys;