import React, { FC, JSX } from 'react';
import { ApiBooking, ApiFlightItem, ApiItemType, ApiTraveler } from '@ibe/api';
import { isHotel } from '@/components/checkout/cart/getAccommodations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCalendar, facDuration, facPerson } from '@/Theme/SVG/Icons';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useTranslation } from '@/app/i18n/client';
import dayjs from 'dayjs';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react';
import { ApiAirlineLogo } from '../../../../api/model';
import Image from '@/components/image/Image';
import { getDefaultFormatPrice } from '@/Util/globals';
import { Props } from '@/types/cms/magnolia';

dayjs.extend(utc);

const Lower: FC<{
  booking: ApiBooking;
  checkoutStore: CheckoutStore;
  airlineLogos?: ApiAirlineLogo[];
  productName?: string;
  pageProps?: Props;
}> = observer(function Lower({
  booking,
  checkoutStore,
  airlineLogos,
  pageProps,
  productName
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const config = useConfig();
  const locale = useCurrentLanguage();
  const hotel = booking.bookedItems.find(item => isHotel(item));
  const packageItem = booking.bookedItems.find(item => item.itemType === ApiItemType.PACKAGE);
  const flights = booking.items.filter(
    item => item.itemType === ApiItemType.FLIGHT
  ) as ApiFlightItem[];
  const numberOfDays = dayjs
    .utc(booking.travelEndDate)
    .diff(dayjs.utc(booking.travelStartDate), 'day');
  const numberOfTravelers = booking.travelers.length;
  const travelerApplication = booking.travelers[0];

  return (
    <div className="confirmation__lower">
      {!!hotel && (
        <div className="confirmation__lower__hotel">
          <div className="confirmation__lower__hotel__name">
            {productName || packageItem?.name || hotel.name}
          </div>
          <div className="confirmation__lower__hotel__info">
            <div className="checkout__data">
              <div>
                <FontAwesomeIcon icon={facDuration} />
                <span>{t(Keys.day, { count: numberOfDays })}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={facCalendar} />
                <span>{`${dayjs
                  .utc(booking.travelStartDate)
                  .format(config.displayFormatDateShort[locale])
                  .slice(0, -5)} - ${dayjs
                  .utc(booking.travelEndDate)
                  .format(config.displayFormatDateShort[locale])}`}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={facPerson} />
                <span>{t(Keys.traveler, { count: numberOfTravelers })}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="confirmation__lower__travelers">
        <div className="confirmation__lower__travelers__inner">
          <div className="confirmation__lower__applicant">
            <div className="confirmation__lower__applicant__label">{t(Keys.travelApplication)}</div>
            <div className="confirmation__lower__applicant__container">
              <div className="confirmation__lower__applicant__address">
                <div>{travelerApplication?.salutation}</div>
                <div>{`${travelerApplication?.firstName} ${travelerApplication?.lastName}`}</div>
                <div>{travelerApplication?.address?.street || ''}</div>
                <div>{`${travelerApplication?.address?.postalCode || ''} ${
                  travelerApplication?.address?.city
                }`}</div>
                <div>
                  {checkoutStore.baseData?.countries?.find(
                    country => country.code === travelerApplication?.address?.countryCode
                  )?.description || travelerApplication?.address?.countryCode}
                </div>
              </div>
              <div className="confirmation__lower__applicant__contact">
                <div>{`${t(Keys.emailLabel)} ${
                  travelerApplication?.communicationDetails?.email || ''
                }`}</div>
                <div>{`${t(Keys.phoneLabel)} ${
                  travelerApplication?.communicationDetails?.phone || ''
                }`}</div>
              </div>
            </div>
          </div>
          <div className="confirmation__lower__travelers__list">
            <div className="confirmation__lower__travelers__list__label">
              {t(Keys.travelParticipantSimple)}
            </div>
            <table>
              <tbody>
                {booking.travelers.map((traveler: ApiTraveler, idx: number) => (
                  <tr key={traveler.id}>
                    <th>{`${idx + 1}. ${t(Keys.travelerAlt)}`}</th>
                    <th>{traveler.salutation}</th>
                    <th>{traveler.firstName}</th>
                    <th>{traveler.lastName}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {!!booking.notes && booking.notes.length > 0 && (
          <div className="confirmation__lower__travelers__remarks">
            <div className="confirmation__lower__travelers__list__label">{t(Keys.notes)}</div>
            <div>{booking.notes[0].text}</div>
          </div>
        )}
      </div>
      {flights.length > 0 && flights[0]?.segment?.length > 0 && (
        <>
          <hr />
          <div className="confirmation__lower__flight">
            <div className="confirmation__lower__flight__headline">{t(Keys.flightData)}</div>
            {flights.map(flight => {
              const firstSegment = flight.segment[0];
              const firstDeparture = dayjs.utc(firstSegment.departure);
              const firstArrival = dayjs.utc(firstSegment.arrival);
              // TODO removable once segment.dateDeviation is fixed with pacific 50.3
              const firstDayDifference = firstArrival
                .startOf('day')
                .diff(firstDeparture.startOf('day'), 'day');

              const lastSegment = flight.segment[flight.segment.length - 1];
              const lastDeparture = dayjs.utc(lastSegment.departure);
              const lastArrival = dayjs.utc(lastSegment.arrival);
              const lastDayDifference = lastArrival
                .startOf('day')
                .diff(lastDeparture.startOf('day'), 'day');

              return (
                <div key={flight.id} className="confirmation__lower__flight__inner">
                  <div className="confirmation__lower__flight__labels">
                    <div>{t(Keys.flightDataDeparture)}</div>
                    <div>{t(Keys.flightDataReturn)}</div>
                  </div>
                  <div className="confirmation__lower__flight__data">
                    <table>
                      <tbody>
                        <tr>
                          <th>{`${firstSegment.origin.description || firstSegment.origin.code} - ${
                            firstSegment.destination.description || firstSegment.destination.code
                          }`}</th>
                          {!checkoutStore.hasInvalidFlights(flights) && (
                            <>
                              <th>
                                {`${firstDeparture.format('HH:mm')} - ${firstArrival.format('HH:mm')}`}
                                {firstDayDifference > 0 && <sup>+{firstDayDifference}</sup>}
                              </th>
                              {!!airlineLogos?.[0]?.logo && (
                                <th>
                                  <Image
                                    image={airlineLogos[0].logo}
                                    alt={airlineLogos[0].logo.title || 'airline logo'}
                                    className="flight-legs__stops__image"
                                    useDefaultLoader
                                    serverSideContext={pageProps?.albConfig.context}
                                    pageProps={pageProps}
                                  />
                                </th>
                              )}
                            </>
                          )}
                        </tr>
                        <tr>
                          <th>{`${lastSegment.origin.description || lastSegment.origin.code} - ${
                            lastSegment.destination.description || lastSegment.destination.code
                          }`}</th>
                          {!checkoutStore.hasInvalidFlights(flights) && (
                            <>
                              <th>
                                {`${lastDeparture.format('HH:mm')} - ${lastArrival.format('HH:mm')}`}
                                {lastDayDifference > 0 && <sup>+{lastDayDifference}</sup>}
                              </th>
                              {lastDayDifference > 0 && <sup>+{lastDayDifference}</sup>}
                              {!!airlineLogos?.[1]?.logo && (
                                <th>
                                  <Image
                                    image={airlineLogos[1].logo}
                                    alt={airlineLogos[1].logo.title || 'airline logo'}
                                    className="flight-legs__stops__image"
                                    useDefaultLoader
                                    serverSideContext={pageProps?.albConfig.context}
                                    pageProps={pageProps}
                                  />
                                </th>
                              )}
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <hr />
      <div className="confirmation__lower__total">
        {t(Keys.totalPrice)}&nbsp;&nbsp;
        {getDefaultFormatPrice(booking.price.finalPrice, locale)}
      </div>
    </div>
  );
});

export default Lower;
