import React, { ChangeEvent, FC, JSX, useEffect, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Keys from '@/Translations/generated/da/Header.json.keys';
import classNames from 'classnames';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { useApi } from '@/Hooks/useApi';
import { ApiWebsiteQuickSearchResultsByCategory } from '@ibe/api';
import WebsiteSearchResults from '@/components/websiteSearch/WebsiteSearchResults';
import { useDebounce } from '@/Hooks/useDebounce';
import { broadcastEvent } from '@/Tracking/trackingSubscriptions';
import { EventType, SearchCategory } from '@/Tracking/types';
import useConfig from '@/Hooks/useConfig';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import { logger } from '@/Util/globals';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import PageUrl from '@/Models/PageUrl';
import Image from '@/components/image/Image';
import { ApiImage } from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';

const WebsiteSearch: FC<{
  isMounted: boolean;
  status: string;
  toggleSearch: (searchIsMounted: boolean) => void;
  image: ApiImage;
}> = ({ toggleSearch, status, isMounted, image }): JSX.Element => {
  const { t } = useTranslation('Header');
  const config = useConfig();
  const locale = useCurrentLanguage();
  const pageProps = useGlobalMGLProps();
  const api = useApi(pageProps?.isAuthor);
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<ApiWebsiteQuickSearchResultsByCategory[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMounted && !!inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isMounted]);

  const doRequest = useDebounce(async (): Promise<void> => {
    if (value.length > 2) {
      setIsLoading(true);
      try {
        broadcastEvent(EventType.SEARCH, config, locale, {
          search: { searchCategory: SearchCategory.WEBSITE, searchTerm: value },
          data: { pageProps }
        });
        const res = await api.websiteSearchQuick(value);
        setResults(!!res?.results ? res.results : []);
      } catch {
        logger('error')('Unable to fetch website search');
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      setResults([]);
    }
  }, 400);

  const onInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    doRequest();
  };

  const onIconClick = (): void => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  };

  return isMounted ? (
    <div
      className={classNames('website-search', {
        'website-search--hidden': status === 'preEnter' || status === 'exiting'
      })}
    >
      <a href={PageUrl.HOME} className="website-search__logo">
        <Image
          pageProps={pageProps}
          image={image}
          width={146}
          height={37}
          useDefaultLoader
          serverSideContext={pageProps?.albConfig.context}
        />
      </a>
      <FontAwesomeIcon
        icon={faTimes}
        className="website-search__close"
        onClick={(): void => toggleSearch(false)}
      />
      <div className="website-search__input">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="website-search__input__magnify"
          onClick={onIconClick}
        />
        <Input
          innerRef={inputRef}
          value={value}
          onChange={onInputChange}
          placeholder={t(Keys.websiteSearchPlaceholder)}
        />
        <span
          className="website-search__input__reset"
          onClick={(): void => {
            setValue('');
            setResults([]);
            onIconClick();
          }}
        >
          {t(Keys.websiteSearchClearInput)}
        </span>
      </div>
      <WebsiteSearchResults
        results={results}
        isLoading={isLoading}
        value={value}
        pageProps={pageProps}
      />
    </div>
  ) : (
    <></>
  );
};

export default WebsiteSearch;
