import { useEffect } from 'react';

const onAnimationStart: EventListenerOrEventListenerObject = (event: any) => {
  'onautofillstart' === event.animationName
    ? autocomplete(event.target)
    : cancelAutocomplete(event.target);
};

const onInput: EventListenerOrEventListenerObject = (event: any) => {
  'insertReplacementText' === event.inputType || !('data' in event)
    ? autocomplete(event.target)
    : cancelAutocomplete(event.target);
};

const autocomplete = (element: HTMLElement) => {
  if (element.hasAttribute('autocompleted')) return;
  element.setAttribute('autocompleted', '');

  var event = new window.CustomEvent('onautocomplete', {
    bubbles: true,
    cancelable: true,
    detail: null
  });

  if (!element.dispatchEvent(event)) {
    (element as unknown as { value: string }).value = '';
  }
};

const cancelAutocomplete = (element: HTMLElement) => {
  if (!element.hasAttribute('autocompleted')) return;
  element.removeAttribute('autocompleted');

  // dispatch event
  element.dispatchEvent(
    new window.CustomEvent('onautocomplete', {
      bubbles: true,
      cancelable: false,
      detail: null
    })
  );
};

export const useAutofill = (): void => {
  useEffect(() => {
    document.addEventListener('animationstart', onAnimationStart, true);
    document.addEventListener('input', onInput, true);

    return (): void => {
      document.removeEventListener('animationstart', onAnimationStart, true);
      document.removeEventListener('input', onInput, true);
    };
  }, []);
};
