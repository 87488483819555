function escapeRegExp(query: string) {
  return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const trimAndHighlightText = (text: string, query: string): string => {
  let result = text;
  if (result.length > 400) {
    result = result.slice(0, 400);
    result = result + ' ...';
  }
  const safeQuery = escapeRegExp(query);
  const regex = new RegExp(`(${safeQuery})`, 'gi');
  return result.replace(regex, '<strong>$1</strong>');
};
