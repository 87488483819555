import React, { FC, Fragment, JSX } from 'react';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';
import { ApiWebsiteQuickSearchResultsByCategory } from '@ibe/api';
import CustomSpinner from '@/components/CustomSpinner/CustomSpinner';
import Link from '@/components/Link';
import { Props } from '@/types/cms/magnolia';
import {
  MEDIAQUERY_DEFAULTS,
  WEBSITE_SEARCH_HEADLINE_PARAM,
  WEBSITE_SEARCH_TYPE_PARAM,
  WEBSITE_SEARCH_VALUE_PARAM
} from '@/Util/globals';
import { useMediaQuery } from '@ibe/components';

export const getBaseString = (question: string) => {
  const textEncoded = new TextEncoder().encode(encodeURIComponent(question));
  const binaryString = String.fromCodePoint(...textEncoded);
  return window?.btoa(binaryString);
};

const WebsiteSearchResults: FC<{
  results: ApiWebsiteQuickSearchResultsByCategory[];
  isLoading: boolean;
  value: string;
  pageProps?: Props;
}> = ({ results, isLoading, value, pageProps }): JSX.Element => {
  const { t } = useTranslation('Header');
  const { siteConfig, rootNodePath } = pageProps || {};
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.md });

  return (
    <div className="website-search__results">
      {isLoading && (
        <div className="website-search__results__spinner">
          <CustomSpinner />
        </div>
      )}
      {!isLoading && results.length > 0 && (
        <div className="website-search__results__content">
          {results.map(result =>
            !!result.value && (result.value.length > 0 || !isMobileView) ? (
              <div key={result.key} className="website-search__results__result">
                <div className="website-search__results__headline">
                  {t((Keys as Record<string, string>)[result.key || ''], {
                    count: result.hits
                  })}
                </div>
                {result.value.map(value => (
                  <div key={value.title} className="website-search__results__link">
                    {result.key === 'faq' ? (
                      <a
                        href={`${value.url}?${WEBSITE_SEARCH_HEADLINE_PARAM}=${getBaseString(value.title)}`}
                      >
                        {value.title}
                      </a>
                    ) : (
                      <a href={value.url}>{value.title}</a>
                    )}
                  </div>
                ))}
                {result.value.length > 0 && !!siteConfig && !!rootNodePath && (
                  <span className="website-search__results__link__to-all">
                    <Link
                      href={`${siteConfig.parameters.websiteSearchPage.replace(
                        rootNodePath,
                        ''
                      )}?${WEBSITE_SEARCH_TYPE_PARAM}=${
                        result.key
                      }&${WEBSITE_SEARCH_VALUE_PARAM}=${value}`}
                      showCaret
                    >
                      {t(Keys.seeAllResults)}
                    </Link>
                  </span>
                )}
              </div>
            ) : (
              <Fragment key={result.key} />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default WebsiteSearchResults;
