import React, { Dispatch, FC, JSX, SetStateAction, useMemo, useState } from 'react';
import CheckoutStore from '@/templates/checkout/CheckoutStore';
import { observer } from 'mobx-react';
import { default404Image } from '@ibe/components';
import { ApiExtraService } from '@ibe/api';
import Image from '@/components/image/Image';
import { getPrice } from '@/Util/globals';
import {
  ApiAccommodation,
  ApiAccommodationFromJSON,
  ApiExtra,
  ApiImageFromJSON
} from '../../../api/model';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Checkout.json.keys';
import { useCurrentLanguage } from '@/Util/CurrentLanguageProvider';
import Button from '@/components/Button';
import classNames from 'classnames';
import TruncateContent from '@/components/truncateContent/TruncateContent';
import { Props } from '@/types/cms/magnolia';

const MealTeaser: FC<{
  mealPackage: ApiExtraService;
  checkoutStore: CheckoutStore;
  isSelected: boolean;
  componentId: string;
  setSelectedMealAsAccommodation: Dispatch<SetStateAction<ApiAccommodation | undefined>>;
  pageProps?: Props;
}> = observer(function MealTeaser({
  mealPackage,
  checkoutStore,
  componentId,
  isSelected,
  setSelectedMealAsAccommodation,
  pageProps
}): JSX.Element {
  const { t } = useTranslation('Checkout');
  const language = useCurrentLanguage();
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const cmsExtra = useMemo(() => {
    return mealPackage.metaInfo?.contentByMetaType?.extraContent?.content?.[mealPackage.cmsCode] as
      | ApiExtra
      | undefined;
  }, [mealPackage]);

  const handleClick = async (): Promise<void> => {
    checkoutStore.isLoading = true;
    if (isSelected) {
      await checkoutStore.deselectItemsInCart(componentId, [mealPackage.id]);
    } else {
      await checkoutStore.selectItemsInCart(componentId, [mealPackage.id]);
    }
  };

  const onTruncate = (isTruncated: boolean): void => {
    setIsTruncated(isTruncated);
  };

  return (
    <div className="checkout-teaser__container">
      <div className="checkout-teaser">
        <div className="checkout-teaser__left">
          {!!cmsExtra?.image ? (
            <Image
              image={cmsExtra.image}
              serverSideContext={pageProps?.albConfig.context}
              pageProps={pageProps}
            />
          ) : (
            <Image
              image={ApiImageFromJSON({
                imageLink: default404Image,
                title: 'default image',
                alt: 'default image',
                width: 100,
                height: 100
              })}
              pageProps={pageProps}
              serverSideContext={pageProps?.albConfig.context}
            />
          )}
        </div>
        <div className="checkout-teaser__center">
          <h4>{cmsExtra?.name || mealPackage.name}</h4>
          {!!cmsExtra?.description && (
            <div>
              <TruncateContent onTruncate={onTruncate}>{cmsExtra.description}</TruncateContent>
            </div>
          )}
          {!!cmsExtra?.description && isTruncated && (
            <div
              className="checkout-teaser__read-more"
              onClick={(): void =>
                setSelectedMealAsAccommodation(
                  ApiAccommodationFromJSON({
                    id: mealPackage.id,
                    name: cmsExtra?.name || mealPackage.name,
                    images: !!cmsExtra?.image ? [cmsExtra.image] : [],
                    shortDescription: cmsExtra?.description,
                    rooms: []
                  })
                )
              }
            >
              {t(Keys.readMore)}
            </div>
          )}
        </div>
        <div className="checkout-teaser__right">
          <div className="checkout-teaser__right__price">
            {!mealPackage?.price?.finalPrice || mealPackage?.price?.finalPrice === 0
              ? t(Keys.included)
              : getPrice(mealPackage.price, language)}
          </div>
          <Button
            className={classNames('checkout-teaser__button', {
              'checkout-teaser__button--selected': isSelected
            })}
            color="primary"
            onClick={handleClick}
          >
            {isSelected ? t(Keys.remove) : t(Keys.add)}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default MealTeaser;
