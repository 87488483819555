'use client';

import React, { FC, JSX } from 'react';
import CheckoutPageClient, { CheckoutProps } from '@/templates/checkout/CheckoutPageClient';
import { useGlobalApi } from '@/Hooks/useGlobalApi';
import { fallbackLng } from '@/app/i18n/settingsClient';
import { usePathname } from 'next/navigation';
import { useWindow } from '@ibe/components';
import CheckoutStore from '@/templates/checkout/CheckoutStore';

const CheckoutPageClientWrapper: FC<Omit<CheckoutProps, 'checkoutStore'>> = (
  props
): JSX.Element => {
  const { pageProps } = props;
  const { api } = useGlobalApi();
  const { siteConfig, rootNodePath, isAuthor, locale = fallbackLng } = pageProps || {};
  const pathname = usePathname();
  const window = useWindow();

  const checkoutStore = new CheckoutStore(api, window, pathname, siteConfig, locale);

  return <CheckoutPageClient {...props} checkoutStore={checkoutStore} />;
};

export default CheckoutPageClientWrapper;
