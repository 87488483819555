import React, { ReactElement } from 'react';
import Image from '@/components/image/Image';
import sanitize from '@/Util/sanitize';
import { facButtonCaret } from '@/Theme/SVG/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiContentArea } from '../../../api/model';
import { ContentRowProps, MagnoliaCountryNode } from '@/types/cms/country';
import { mapToApiContentRow } from '@/Util/globals';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { EditableAreaOrPageChild, MagnoliaNode, Props } from '@/types/cms/magnolia';
import { MagnoliaContinentNode } from '@/types/cms/continent';
import { MagnoliaCityNode } from '@/types/cms/city';
import { MagnoliaTravelTypeNode } from '@/types/cms/travelType';
import { MagnoliaProductNode } from '@/types/cms/product';
import { Link } from '@/Hooks/useReactTransitionProgress';

const ContentRowColumn = (
  props: ApiContentArea & { isLeftSide?: boolean; isRightSide?: boolean; pageProps?: Props }
): ReactElement => {
  const {
    isLeftSide,
    isRightSide,
    leftSide,
    rightSide,
    imageLeft,
    imageRight,
    textLeft,
    textRight,
    pageProps
  } = props;
  const image =
    isLeftSide && leftSide === 'image' && !!imageLeft
      ? imageLeft
      : isRightSide && rightSide === 'image' && !!imageRight
        ? imageRight
        : undefined;
  const text =
    isLeftSide && leftSide === 'text' && !!textLeft
      ? textLeft
      : isRightSide && rightSide === 'text' && !!textRight
        ? textRight
        : undefined;

  const globalProps = useGlobalMGLProps();

  let linkAttr;

  if (text?.cta && text?.cta.linkType) {
    linkAttr = getNextLinkAttrs(
      {
        linkTypepage: text.cta.linkTypepage as MagnoliaNode,
        linkType: text.cta.linkType,
        linkTypecontinent: text.cta.linkTypecontinent as MagnoliaContinentNode,
        linkTypecountry: text.cta.linkTypecountry as MagnoliaCountryNode,
        linkTypeexternal: text.cta.linkTypeexternal,
        linkTypecity: text.cta.linkTypecity as MagnoliaCityNode,
        linkTypetravelType: text.cta.linkTypetravelType as MagnoliaTravelTypeNode,
        linkTypedownload: text.cta.linkTypedownload,
        linkTypeproduct: text.cta.linkTypeproduct as MagnoliaProductNode
      },
      globalProps
    );
  }

  return (
    <>
      {!!image && (
        <div
          className={`content-row__image ${
            isLeftSide ? 'content-row__image--left' : 'content-row__image--right'
          }`}
        >
          <Image
            image={image}
            alt={image.title || 'content image'}
            width={400}
            height={302}
            aspectRatio="4-3"
            serverSideContext={pageProps?.albConfig.context}
            pageProps={pageProps}
          />
        </div>
      )}
      {!!text && (
        <div
          className={`content-row__text ${
            isLeftSide ? 'content-row__text--left' : 'content-row__text--right'
          }`}
        >
          {!!text.title && <h4>{text.title}</h4>}
          <div dangerouslySetInnerHTML={{ __html: sanitize(text.richText || '', true) }} />
          {!!linkAttr && !!text.cta && text.cta.linkType !== 'none' && (
            <div className="content__row__cta">
              <a className="btn btn-primary" href={linkAttr.href} target={linkAttr.target}>
                <span>{text.cta.label}</span>
                <FontAwesomeIcon icon={facButtonCaret} />
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ContentRow = (
  props: (ContentRowProps | ApiContentArea) & Partial<EditableAreaOrPageChild>
): ReactElement => {
  const { leftSide, rightSide, pageProps } = props;
  let transformedApiContentRow = !!props ? mapToApiContentRow(props) : {};

  return (
    <div className="content-row">
      {leftSide === 'text' && rightSide === 'image' ? (
        <>
          <div className="content-row__column">
            <ContentRowColumn {...transformedApiContentRow} isRightSide pageProps={pageProps} />
          </div>
          <div className="content-row__column">
            <ContentRowColumn {...transformedApiContentRow} isLeftSide pageProps={pageProps} />
          </div>
        </>
      ) : (
        <>
          {leftSide !== 'empty' && (
            <div className="content-row__column">
              <ContentRowColumn {...transformedApiContentRow} isLeftSide pageProps={pageProps} />
            </div>
          )}
          {rightSide !== 'empty' && (
            <div className={`content-row__column${rightSide === 'image' ? ' d-xs-flex' : ''}`}>
              <ContentRowColumn {...transformedApiContentRow} isRightSide pageProps={pageProps} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContentRow;
