import React, { FC, JSX } from 'react';
import { ApiWebsiteFaqSearch } from '@ibe/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/app/i18n/client';
import Keys from '@/Translations/generated/da/Header.json.keys';
import { WEBSITE_SEARCH_HEADLINE_PARAM } from '@/Util/globals';
import LazyLoad from 'react-lazyload';
import FadeIn from '@/components/SearchForResults/FadeIn';
import { getBaseString } from '@/components/websiteSearch/WebsiteSearchResults';

const WebsiteSearchFullFaqList: FC<{
  results: ApiWebsiteFaqSearch[];
  activeIndex: number;
}> = ({ results, activeIndex }): JSX.Element => {
  const { t } = useTranslation('Header');

  const tabIndex = 3;
  return (
    <div className="website-search-full__results__list">
      {results.map(r => {
        return (
          <LazyLoad key={r.question} throttle={300} offset={400} height={420} scroll resize>
            <FadeIn>
              <div
                style={activeIndex !== tabIndex ? { maxHeight: 0 } : {}}
                key={r.question}
                className="website-search-full__results__list--row"
              >
                <b>{r.question}</b>
                <a href={`${r.url}?${WEBSITE_SEARCH_HEADLINE_PARAM}=${getBaseString(r.question)}`}>
                  <span>{t(Keys.learnMore)}</span>
                  <FontAwesomeIcon icon={faCircleArrowRight} />
                </a>
                <p>{r.answer}</p>
              </div>
            </FadeIn>
          </LazyLoad>
        );
      })}
    </div>
  );
};

export default WebsiteSearchFullFaqList;
