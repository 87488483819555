'use client';

import React, { JSX } from 'react';
import Keys from '@/Translations/generated/da/country.json.keys';
import Intro from '@/components/intro/Intro';
import { useTranslation } from '@/app/i18n/client';
import SightseeingList from '@/components/sightseeing/SightseeingList';
import { THEMES } from '@/Util/globals';
import Image from '@/components/image/Image';
import { ApiCountry } from '../../../api/model';
import sanitize from '@/Util/sanitize';
import { EditableAreaOrPageChild, Props } from '@/types/cms/magnolia';

const CountrySightseeing = ({
  country,
  pathname,
  pageProps
}: {
  country?: ApiCountry;
  numberOfProductsForCountry: number;
  pathname: string;
  pageProps?: Props;
} & Partial<EditableAreaOrPageChild>): JSX.Element => {
  const {
    name,
    sightseeingItems,
    introTitleSightseeing,
    introTextSightseeing,
    introImage,
    themeIntroSightseeing,
    themeSightseeingItems
  } = country || {};

  const { t } = useTranslation('country');

  return (
    <>
      <Intro
        headline={
          introTitleSightseeing ? introTitleSightseeing : `${t(Keys.headlineSightseeing)} ${name}`
        }
        pageProps={pageProps}
        pathname={pathname}
        singleColumn={
          <div className="intro__single-column">
            {!!introImage && (
              <div className="intro__single-column__image intro__single-column__image--right">
                <Image
                  className="intro__container__image"
                  image={introImage}
                  alt="intro image"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  aspectRatio="4-3"
                  serverSideContext={pageProps?.albConfig.context}
                  pageProps={pageProps}
                />
              </div>
            )}
            {!!introTextSightseeing && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(introTextSightseeing, true)
                }}
              />
            )}
          </div>
        }
        theme={themeIntroSightseeing || THEMES.blue}
      />
      {!!sightseeingItems && sightseeingItems.length > 0 && (
        <div className={themeSightseeingItems || THEMES.blue}>
          <SightseeingList
            sightseeingItems={sightseeingItems}
            theme={themeSightseeingItems || THEMES.blue}
            pageProps={pageProps}
          />
        </div>
      )}
    </>
  );
};

export default CountrySightseeing;
