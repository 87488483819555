'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { facCaretRightThin, facMinus } from '@/Theme/SVG/Icons';
import classNames from 'classnames';
import { MagnoliaNode } from '@/types/cms/magnolia';
import EditableArea from '@/components/magnoliaPage/EditableArea/EditableArea';
import { useSearchParams } from 'next/navigation';
import { WEBSITE_SEARCH_HEADLINE_PARAM } from '@/Util/globals';
import { useWindow } from '@ibe/components';

interface AccordionElementProps {
  accordionBody: MagnoliaNode;
  headerText: string;
  isOpen: boolean;
  openHandler: () => void;
}

const AccordionElement = ({ accordionBody, headerText }: AccordionElementProps) => {
  const searchParams = useSearchParams();
  const window = useWindow();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    const searchParam = searchParams.get(WEBSITE_SEARCH_HEADLINE_PARAM) || '';
    let textDecoded = '';
    if (!!searchParam) {
      const baseString = window?.atob(searchParam);
      if (!!baseString) {
        const binaryString = Uint8Array.from(
          baseString,
          stringToMap => stringToMap.codePointAt(0) || 0
        );
        textDecoded = decodeURIComponent(new TextDecoder().decode(binaryString));
      }
    }
    if (!!textDecoded && textDecoded === headerText) {
      setIsOpen(true);

      if (!!containerRef.current) {
        timer = setTimeout(() => {
          const element = containerRef?.current;
          if (element) {
            const top = element.getBoundingClientRect().top + (window?.scrollY || 0) - 95; // header offset
            window?.scrollTo({ top, behavior: 'smooth' });
          }
        }, 1000);
      }
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer);
      }
    };
  }, [headerText, containerRef, containerRef.current]);

  return (
    <div className="accordion-element__print">
      <div ref={containerRef} className="accordion-element">
        <Button className="accordion-element__button" onClick={() => setIsOpen(value => !value)}>
          <div className="accordion-element__group__header">{headerText}</div>
          {isOpen ? (
            <FontAwesomeIcon
              className={classNames('accordion-element__chevron', {
                'accordion-element__chevron--up': isOpen
              })}
              icon={facMinus}
            />
          ) : (
            <FontAwesomeIcon
              className="accordion-element__chevron accordion-element__chevron--down"
              icon={facCaretRightThin}
            />
          )}
        </Button>
        <Collapse isOpen={isOpen}>
          <Card className="accordion-element__card">
            {!!accordionBody ? <EditableArea content={accordionBody} /> : <></>}
          </Card>
        </Collapse>
      </div>
      <div className="accordion-element__section__print">
        <div className="accordion-element__group__header">{headerText}</div>
        <div className="accordion-element__body">
          {!!accordionBody ? <EditableArea content={accordionBody} /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default AccordionElement;
