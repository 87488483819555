import CheckoutStore from '@/templates/checkout/CheckoutStore';
import {
  ApiBookedItem,
  ApiBooking,
  ApiExtraUnit,
  ApiItemType,
  ApiPrice,
  ApiPriceFromJSON
} from '@ibe/api';
import { API_ITEM_SERVICE_CODE } from '@/Util/globals';
import { getServiceTypeCode } from '@/components/checkout/cart/getUpgrades';
import { getExtensions } from '@/components/checkout/cart/getExtensions';

export type PurchaseRelatedService = { name: string; totalPrice: number; pricePerPerson: number };

export type PurchasesWrapper = {
  type: API_ITEM_SERVICE_CODE;
  bookedItem: ApiBookedItem;
  relatedServices?: Array<PurchaseRelatedService>;
};

export type AdditionalPurchasesData = {
  purchases: PurchasesWrapper[];
  totalPricePerPerson: ApiPrice;
};

const getExcursions = (packages: boolean, booking?: ApiBooking): Array<PurchasesWrapper> => {
  return (
    booking?.bookedItems
      ?.filter(bookedItem => {
        const rate = booking?.items?.find(item => item.id === bookedItem.idParent);
        const unit = booking?.items?.find(item => item.id === rate?.idParent) as
          | ApiExtraUnit
          | undefined;
        return (
          !!bookedItem.price?.finalPrice &&
          bookedItem.itemType === ApiItemType.EXTRA &&
          unit?.unitCode ===
            (packages
              ? API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION_PACKAGE
              : API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION)
        );
      })
      .map(bookedItem => ({
        type: packages
          ? API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION_PACKAGE
          : API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION,
        bookedItem
      })) || []
  );
};

export const getAdditionalPurchases = (checkoutStore: CheckoutStore): AdditionalPurchasesData => {
  const { booking, packageCart: { packageModel } = {} } = checkoutStore;
  const excursionPackages = getExcursions(true, booking);
  const singleExcursions = getExcursions(false, booking);
  const packageComponents = packageModel?.packageDetails?.[0]?.components || [];

  const meals =
    booking?.bookedItems?.filter(bookedItem => {
      return (
        bookedItem.itemType === ApiItemType.EXTRA &&
        getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.MEAL
      );
    }) || [];

  const visas =
    booking?.bookedItems?.filter(bookedItem => {
      return (
        bookedItem.itemType === ApiItemType.EXTRA &&
        getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.VISA
      );
    }) || [];

  const genericExtras =
    booking?.bookedItems?.filter(bookedItem => {
      return (
        bookedItem.itemType === ApiItemType.EXTRA &&
        (getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.EXTRA ||
          getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.FO_EXTRA ||
          getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.TRF_EXTRA)
      );
    }) || [];

  const extensionHotelsAndExcursions =
    booking?.bookedItems?.filter(bookedItem => {
      return (
        bookedItem.itemType === ApiItemType.EXTRA &&
        (getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.EXTENSION_HOTEL ||
          getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.EXTENSION_EXCURSION)
      );
    }) || [];

  const extensions =
    booking?.bookedItems?.filter(bookedItem => {
      return (
        bookedItem.itemType === ApiItemType.EXTRA &&
        (getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.EXTENSION_PACKAGE ||
          getServiceTypeCode(bookedItem) === API_ITEM_SERVICE_CODE.EXTENSION_PACKAGE_ALT1)
      );
    }) || [];

  const purchases: PurchasesWrapper[] = [
    ...visas.map(bookedItem => ({ type: API_ITEM_SERVICE_CODE.VISA, bookedItem })),
    ...meals.map(bookedItem => ({ type: API_ITEM_SERVICE_CODE.MEAL, bookedItem })),
    ...genericExtras.map(bookedItem => ({ type: API_ITEM_SERVICE_CODE.EXTRA, bookedItem })),
    ...excursionPackages,
    ...singleExcursions,
    ...getExtensions(extensions, packageComponents, extensionHotelsAndExcursions)
  ];
  const firstWithPrice = purchases.find(({ bookedItem: item }) =>
    !!item.priceByPersonId ? !!Object.values(item.priceByPersonId)[0] : false
  );
  return {
    purchases,
    totalPricePerPerson: ApiPriceFromJSON({
      ...(!!firstWithPrice?.bookedItem?.priceByPersonId
        ? Object.values(firstWithPrice.bookedItem.priceByPersonId)[0]
        : {}),
      finalPrice: purchases.reduce((total: number, current: PurchasesWrapper) => {
        let currentPrice = 0;
        if (current.bookedItem.priceByPersonId) {
          currentPrice += Object.values(current.bookedItem.priceByPersonId)[0].finalPrice;
        }
        return total + currentPrice;
      }, 0),
      modifiers: []
    })
  };
};
