'use client';

import React, { FC, useState } from 'react';
import SimpleAccordion, { AccordionData } from '@/components/accordion/SimpleAccordion';

const AccordionGroups: FC<{ groups: Array<AccordionData>[] }> = ({ groups }) => {
  const [selectedAccordion, setSelectedAccordion] = useState<string | null>('00');

  return (
    <>
      {groups.map((group, idx) => (
        <SimpleAccordion
          key={group[0].group?.[0].sectionHeader || idx}
          groups={group}
          externalPrefix={idx}
          externalSelectedAccordion={selectedAccordion}
          externalSetSelectedAccordion={setSelectedAccordion}
        />
      ))}
    </>
  );
};
export default AccordionGroups;
