import CheckoutStore from '@/templates/checkout/CheckoutStore';

export const stepCreator = (checkoutStore: CheckoutStore): number[] => {
  let subSteps: number[];

  // mainStep 1, subStep 1 -> product selection + flight (product selection always present)
  subSteps = [1];
  // mainStep 1, subStep 2 -> hotels
  if (checkoutStore.hotels?.length > 0) {
    subSteps[0] = subSteps[0] + 1;
  }
  // mainStep 1, subStep 3 -> opera, bus, transfer
  if (
    checkoutStore.operas.length > 0 ||
    checkoutStore.bus.length > 0 ||
    checkoutStore.transfers.length > 0
  ) {
    subSteps[0] = subSteps[0] + 1;
  }
  subSteps = [...subSteps, 0];
  // mainStep 2, subStep 1
  if (
    !!checkoutStore.visa ||
    checkoutStore.mealPackages.length > 0 ||
    checkoutStore.extras.length > 0
  ) {
    subSteps[1] = subSteps[1] + 1;
  }
  // mainStep 2, subStep 2
  if (checkoutStore.excursionPackages.length > 0 || checkoutStore.singleExcursions.length > 0) {
    subSteps[1] = subSteps[1] + 1;
  }
  // mainStep 2, subStep 3
  if (checkoutStore.extensions.length > 0) {
    subSteps[1] = subSteps[1] + 1;
  }
  subSteps = [...subSteps, 2]; // participants form + payment/TaCs always present
  // mainStep 3, subStep 3
  subSteps[2] = subSteps[2] + 1; // step always present because of media codes
  // mainStep 4 -> confirmation
  subSteps = [...subSteps, 1];
  // remove indices with 0 subSteps
  return subSteps.filter(step => step > 0);
};
