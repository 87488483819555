'use client';

import React, { FC, useState, Fragment } from 'react';
import { useTranslation } from '@/app/i18n/client';
import ContinentImage from './ContinentImage';
import { useMediaQuery } from '@ibe/components';
import { MEDIAQUERY_DEFAULTS } from '@/Util/globals';
import { ContinentMapProps, Continents, getContinentData } from '@/components/continentMap/helpers';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import { MagnoliaContinentNode } from '@/types/cms/continent';
import { getNextLinkAttrs } from '@/components/linkList/Link';
import { Link } from '@/Hooks/useReactTransitionProgress';
import { Props } from '@/types/cms/magnolia';

export const getContinentLink = (link?: MagnoliaContinentNode, globalProps?: Props): string => {
  return getNextLinkAttrs({ linkType: 'continent', linkTypecontinent: link }, globalProps).href;
};

const InteractiveMap: FC<ContinentMapProps> = props => {
  const [activeContinent, setActiveContinent] = useState<string>('world');
  const { t } = useTranslation('continentMap');
  const isDesktop = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.lg });
  const globalProps = useGlobalMGLProps();

  const handleContinentEnter = (continent: Continents) => {
    setActiveContinent(continent);
  };

  const handleContinentLeave = () => {
    setActiveContinent(Continents.WORLD);
  };

  return isDesktop ? (
    <div className="continent-map">
      <div className="continent-map__map-container">
        {getContinentData(t, props, props.continentData).map(continent => (
          <Fragment key={continent.name}>
            <ContinentImage
              name={continent.name}
              isActive={activeContinent === continent.name}
              pageProps={globalProps}
            />
            {!!continent.label && !!continent.link && (
              <>
                <a
                  href={getContinentLink(continent.link, globalProps)}
                  className="continent-map__link"
                  style={{ top: continent.top, left: continent.left }}
                  onMouseEnter={() => handleContinentEnter(continent.name)}
                  onMouseLeave={handleContinentLeave}
                >
                  {continent.label}
                </a>
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default InteractiveMap;
