/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-04-09 15:35:28 */

const Keys = {
  validationRequired: 'validationRequired',
  validationEmail: 'validationEmail',
  validationConfirmEmail: 'validationConfirmEmail',
  validationCheckboxGroupRequired: 'validationCheckboxGroupRequired',
  validationNumber: 'validationNumber',
  reopenFormButtonText: 'reopenFormButtonText'
}; export default Keys;