'use client';

import React, { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import createCache from '@emotion/cache';
import { ThemeProvider, CacheProvider } from '@emotion/react';
import getTheme from '../../Theme/theme';
import { enableStaticRendering } from 'mobx-react';
import { boot, initTranslations } from '@/Config/config';
import {
  GlobalTranslationFunction,
  GlobalTranslationContextProvider,
  IsNextEnvContext
} from '@ibe/components';
import isClient, { logger } from '../../Util/globals';
import { CookiesProvider } from 'react-cookie';
import GlobalWindowProvider from '../../Util/GlobalWindowProvider';
import { ClientSideBootingFinishedContext } from '@/Hooks/useClientSideBootingFinished';
import { Props } from '@/types/cms/magnolia';
import { IsBookingInitializedProvider } from '@/Hooks/useIsBookingInitialized';
import initTrackingSubscriptions from '@/Tracking/trackingSubscriptions';
import { GlobalMGLPropsContextProvider } from '@/Util/GlobalMGLPropsContext';
import { useMount } from 'react-use';
import { nameSpaces, useTranslation } from '@/app/i18n/client';
import CurrentLanguageProvider from '@/Util/CurrentLanguageProvider';
import ErrorBoundary from '@/components/ErrorBoundary';
import AlbConfigModel from '@/Config/AlbConfigModel';
import { logger as localLogger } from '@/Util/globals';
import SuspenseFallbackComponent from '@/components/SuspenseFallbackComponent/SuspenseFallbackComponent';
import { useRouter } from 'next/navigation';
import { initTracking } from '@/Tracking/setup';
import { configService } from '@/Config/configService';
import { usePageViewEventOnCookieConsent } from '@/Hooks/usePageViewEventOnCookieConsent';
import { fallbackLng } from '@/app/i18n/settingsClient';
import { GlobalApiProvider } from '@/Hooks/useGlobalApi';

const cache = createCache({ key: 'next' });

export type ApplicationProps = {
  isProduction: boolean;
  pageProps: Props;
  locale: string;
  albConfig: AlbConfigModel;
  isLocal: string;
  withoutSuspense?: boolean;
  renderOnClientBootstrappingFinished?: boolean;
};

const ContextProvider = ({
  pageProps,
  isProduction,
  children,
  locale,
  albConfig,
  isLocal,
  withoutSuspense,
  renderOnClientBootstrappingFinished
}: PropsWithChildren<ApplicationProps>) => {
  enableStaticRendering(!isClient());

  const router = useRouter();
  const [currentConfig, setCurrentConfig] = useState<AlbConfigModel | undefined>(undefined);

  const {
    magnoliaContext,
    siteConfig,
    magnoliaHost,
    magnoliaHostClient,
    translationsVersion,
    activateCache,
    isAuthor
  } = pageProps;
  const [clientSideBootingFinished, setClientSideBootingFinished] = useState<boolean>(false);

  (async (): Promise<void> => {
    if (!isClient()) {
      await initTranslations(isLocal, magnoliaHost, locale, translationsVersion, activateCache);
    }
  })();

  usePageViewEventOnCookieConsent(
    pageProps,
    currentConfig,
    magnoliaContext.nodePath !== siteConfig.parameters.checkoutPage &&
      magnoliaContext.nodePath !== siteConfig.parameters.productPage
  );

  useMount(async (): Promise<void> => {
    const config = await boot(
      albConfig,
      isLocal,
      isClient() ? magnoliaHostClient : magnoliaHost,
      locale,
      false,
      pageProps
    );
    setCurrentConfig(config);
    if (
      !magnoliaContext?.isMagnoliaEdit &&
      !magnoliaContext?.isMagnolia &&
      siteConfig?.parameters?.useConsentManager
    ) {
      await initTracking(configService.get(), !isProduction, locale);
      initTrackingSubscriptions(config, locale);
      logger()('Tracking initialized...');
    }
    setClientSideBootingFinished(true);
  });

  useEffect(() => {
    const onKeyup = (e: KeyboardEvent): void => {
      if (e.ctrlKey && e.shiftKey && e.altKey && e.code === 'KeyN' && !isProduction) {
        localLogger()(nameSpaces);
      }
    };

    document.addEventListener('keyup', onKeyup);

    return () => {
      document.removeEventListener('keyup', onKeyup);
    };
  }, []);

  const renderContent = () => {
    return !renderOnClientBootstrappingFinished ||
      (renderOnClientBootstrappingFinished && clientSideBootingFinished) ? (
      <ErrorBoundary router={router} siteConfig={siteConfig} rootNodePath={pageProps.rootNodePath}>
        <CacheProvider value={cache}>
          <GlobalWindowProvider>
            <GlobalTranslationContextProvider
              globalTranslation={useTranslation as unknown as GlobalTranslationFunction}
            >
              <CurrentLanguageProvider lng={locale || fallbackLng}>
                <IsNextEnvContext.Provider value={true}>
                  <GlobalMGLPropsContextProvider {...pageProps}>
                    <ClientSideBootingFinishedContext.Provider value={clientSideBootingFinished}>
                      <IsBookingInitializedProvider>
                        <ThemeProvider theme={getTheme()}>
                          <CookiesProvider>
                            <GlobalApiProvider isAuthor={isAuthor}>{children}</GlobalApiProvider>
                          </CookiesProvider>
                        </ThemeProvider>
                      </IsBookingInitializedProvider>
                    </ClientSideBootingFinishedContext.Provider>
                  </GlobalMGLPropsContextProvider>
                </IsNextEnvContext.Provider>
              </CurrentLanguageProvider>
            </GlobalTranslationContextProvider>
          </GlobalWindowProvider>
        </CacheProvider>
      </ErrorBoundary>
    ) : (
      <></>
    );
  };

  return withoutSuspense ? (
    renderContent()
  ) : (
    <Suspense fallback={<SuspenseFallbackComponent logoOnly />}>{renderContent()}</Suspense>
  );
};

export default ContextProvider;
